// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-style-guide-src-template-js": () => import("./../../../node_modules/gatsby-theme-style-guide/src/template.js" /* webpackChunkName: "component---node-modules-gatsby-theme-style-guide-src-template-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-explore-search-index-tsx": () => import("./../../../src/pages/explore/search/index.tsx" /* webpackChunkName: "component---src-pages-explore-search-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-submission-guidelines-tsx": () => import("./../../../src/pages/submission-guidelines.tsx" /* webpackChunkName: "component---src-pages-submission-guidelines-tsx" */),
  "component---src-pages-submit-research-tsx": () => import("./../../../src/pages/submit-research.tsx" /* webpackChunkName: "component---src-pages-submit-research-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-channels-author-index-tsx": () => import("./../../../src/templates/channels/author/index.tsx" /* webpackChunkName: "component---src-templates-channels-author-index-tsx" */),
  "component---src-templates-channels-organisation-index-tsx": () => import("./../../../src/templates/channels/organisation/index.tsx" /* webpackChunkName: "component---src-templates-channels-organisation-index-tsx" */),
  "component---src-templates-channels-resource-index-tsx": () => import("./../../../src/templates/channels/resource/index.tsx" /* webpackChunkName: "component---src-templates-channels-resource-index-tsx" */),
  "component---src-templates-listing-collections-index-tsx": () => import("./../../../src/templates/listing/collections/index.tsx" /* webpackChunkName: "component---src-templates-listing-collections-index-tsx" */),
  "component---src-templates-listing-industries-index-tsx": () => import("./../../../src/templates/listing/industries/index.tsx" /* webpackChunkName: "component---src-templates-listing-industries-index-tsx" */),
  "component---src-templates-listing-insights-for-index-tsx": () => import("./../../../src/templates/listing/insights-for/index.tsx" /* webpackChunkName: "component---src-templates-listing-insights-for-index-tsx" */),
  "component---src-templates-listing-locations-index-tsx": () => import("./../../../src/templates/listing/locations/index.tsx" /* webpackChunkName: "component---src-templates-listing-locations-index-tsx" */),
  "component---src-templates-listing-population-groups-index-tsx": () => import("./../../../src/templates/listing/population-groups/index.tsx" /* webpackChunkName: "component---src-templates-listing-population-groups-index-tsx" */),
  "component---src-templates-listing-social-impacts-index-tsx": () => import("./../../../src/templates/listing/social-impacts/index.tsx" /* webpackChunkName: "component---src-templates-listing-social-impacts-index-tsx" */)
}

