import React from "react";
import "normalize.css/normalize.css";
import { createGlobalStyle } from "styled-components";
import { ThemeProvider, Styled } from "theme-ui";
import theme from "@src/theme";

export const GlobalStyle = createGlobalStyle`


  * {
    box-sizing: border-box;

  }


a {
  color: inherit;
  text-decoration: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}


  button {
    cursor: pointer;
  }

  html {
    -webkit-print-color-adjust: exact;
    font-family: ${theme.fonts.body}, sans-serif, 'Arial';
    font-weight: 300;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    color: ${theme.colors.darkGrey};
    overflow-x: hidden;
    background: #fff;
    letter-spacing: -0.16px;
  }


  body {
    min-height: 100%;
  }



  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    /* li {
      list-style: inside;
    margin-bottom: 5px;
    } */
  } 

  /* ol {
    li {
      padding-inline-start: 20px;
      margin-bottom: 5px;
    }
  } */

  button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }

  .link-hover {
    text-decoration: underline;
      text-decoration-color: transparent;
     * {
      text-decoration: underline;
      text-decoration-color: transparent;
    }
 

    &:hover {
      text-decoration-color: inherit;

       * {
        text-decoration-color: inherit;
      }
    }
  }

  .link-hover-invert {
    text-decoration: underline;
    text-decoration-color: inherit;
    &:hover {
      text-decoration: underline;
      text-decoration-color: transparent;
    }
  }

  .bg-hover {
    &:hover{
      background-color: #eff2f6;
    }
  }



  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}




ul {
  margin: 0;
}

button, div[role="button"] {
  outline: none;
}
`;

export default ({ element }) => (
  <React.Fragment>
    <ThemeProvider theme={theme}>

      <Styled.root>{element}</Styled.root>
    </ThemeProvider>
  </React.Fragment>
);
