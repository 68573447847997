export const pxToRem = (pxSize, rootSize = 16) => {
  return (pxSize / rootSize).toFixed(2);
};

let useCustomProperties = false;

export const rem = (pxSize) => {
  return `${pxToRem(pxSize)}rem`;
};

export const GRID_GUTTERS = ["16px", "18px", "24px", null, "48px"];
// spacing

// 64
// 120

export const spacePx = [
  0,
  8, //
  16,
  24,
  32,
  40,
  48,
  56,
  64,
  72,
  80,
  88,
  96,
  104,
  112,
  120,
  128,
];
export const space = spacePx.map((i) => rem(i));
export const fontSizePx = [16, 18, 24, 26, 48, 56, 76];
export const fontSizes = fontSizePx.map((i) => rem(i));

const theme = {
  useCustomProperties,
  // Fonts
  fonts: {
    body: "'Name', sans-serif",
  },
  // Colors
  colors: {
    // csi
    csiBlue: "#CDDFF9",
    csiYellow: "#F2E391",
    csiRed: "#F0ABAF",
    csiTeal: "#ABE3F0",
    csiPink: "#F2D8FF",
    lightGrey: "#F4F7FB",
    csiLightGrey: "#F4F7FB",
    csiDarkGrey: "#20222B",
    csiBeige: "#F9DAB6",
    csiGreen: "#DDEDD1",
    csiMidGrey: "#616161",

    blood: "#EC3838",
    coal: "#161515",
    morning: "#FBE8F1",
    light: "#F9F9F9",
    stream: "#AEE0FA",
    gum: "#E4E4D1",
    smoke: "#E9E9E8",
    char: "#2C3026",
    hakea: "#B4CCC2",
    stormcloud: "#8CA1BF",
    lemon: "#FFF100",
    beech: "#F9D8A1",
    smoke: "#E9E9E8",
    sap: "#AC8669",
    bubblegum: "#FF69B4",
    lipstick: "#EF3A57",
    reed: "#3CBCA3",
    squash: "#FAF06A",
    lake: "#47C7EF",
    pumpkin: "#F77A43",
    mint: "#A8DCD9",
    clay: "#F98A8A",
    lavender: "#DACAE3",
    twilight: "#6C85BF",

    // general
    grey: "#E0E0E0",
    white: "#FFFFFF",
    black: "#222122",
    darkGrey: "#20222B",
    pink: "#F2D8FF",
    blue: "#8ED6F0",
    red: "#F37361",
    yellow: "#FCDF5C",
    darkPurple: "#3A2A55",
    turquoise: "#54C1B8",
    // core
    purple: "#6A5095",
    corePink: "#FFD1DC",
    // rainbow
    rainbowOrange: "#F89B3C",
    rainbowGreen: "#A3BB4A",
    // trans
    transBlue: "#4EC9F5",
    transPink: "#F391BC",
  },

  typography: {
    paragraphXS: {
      fontSize: [rem(14), null, rem(14)],
      lineHeight: 1.35,
      mt: 0,
    },
    paragraphS: {
      fontSize: [rem(14), null, rem(16)],
      lineHeight: 1.35,
      mt: 0,
    },
    paragraphM: {
      fontSize: [rem(16), null, rem(18)],
      lineHeight: 1.35,
      mt: 0,
      letterSpacing: '-0.4px'
    },
    headingXL: {
      fontSize: [rem(18), null, rem(24)],
      mt: 0,
      fontWeight: 600,
    },
    headingXXL: {
      fontSize: [rem(30), null, rem(54)],
      mt: 0,
      fontWeight: 600,
      letterSpacing: '-1.5px'
    },
    headingL: {
      fontSize: [rem(20)],
      lineHeight: 1.25,
      mt: 0,
      letterSpacing: '-0.5px'
    },
    headingM: {
      fontSize: [rem(14), null, rem(18)],
      mt: 0,
      fontWeight: 600
    },
    headingS: {
      fontSize: [rem(14), null, rem(16)],
      mt: 0,
      fontWeight: 600,
      lineHeight: 1.15,
    },
    headingXLarge: {
      fontSize: ["3.5em", "3.95em"], //66
      fontFamily: "heading",
      letterSpacing: "-1px",
    },
    headingLarge: {
      fontSize: ["32px", "42px", "3.35em!important"], //56
      fontFamily: "heading",
      letterSpacing: "-1px",
    },
    headingMedium: {
      fontSize: "1.91em!important", //32
      fontFamily: "heading",
      letterSpacing: "-1px",
      lineHeight: 1.1,
    },
    headingSmall: {
      fontSize: ["16px", null, null, "0.95em!important"], //16
      fontFamily: "heading",
      letterSpacing: "-1px",
    },
    bodyLarge: {
      fontSize: "1.68em!important", //28
      fontFamily: "body",
      lineHeight: 1.2,
    },
    bodyMedium: {
      fontSize: ["22px", null, "1.32em!important"], //22
      fontFamily: "body",
      lineHeight: 1.2,
    },
    link: {
      fontSize: "1.2em!important", //20
      fontFamily: "heading",
    },
    sizing: {
      bodyCopy: {
        fontSize: [rem(14), null, null, rem(20)],
      },
      bodyCopyLarge: {
        fontSize: [rem(16), null, null, rem(22)],
      },
    },
  },
  buttons: {
    primary: {
      bg: "char",
      color: "stream",
    },
  },
  // Sizing and spacing
  sizes: {
    container: 1500,
  },
  breakpoints: [
    "580px", //0
    "920px", //1
    "1176px", //2
    "1478px", //3
    "1842px", //4
  ],
  space,

  fontSizes,
  fontWeights: {
    body: 500,
    heading: 900,
  },
  // Text variants
  text: {
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.2em",
    },
  },
  images: {
    productImageListItem: {
      width: 112,
      height: 112,
    },
  },
  //Misc
  styles: {
    root: {
      color: "#20222B",
      backgroundColor: "#ffffff",
      textRendering: "optimizeLegibility !important",
      "-webkit-font-smoothing": "antialiased !important",
    },
    h1: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: 0.95,
      fontSize: ["3.4em", "3.95em"], //56
      letterSpacing: "-1px",
    },
    h2: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: 1.15,
      fontSize: ["32px", "42px", "3.35em!important"], //56
      letterSpacing: "-1px",
    },
    h3: {
      fontSize: "1.91em!important", //32
      fontFamily: "heading",
      letterSpacing: "-1px",
      lineHeight: 1.1,
    },
    h4: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: 1.15,
      fontSize: [rem(18), rem(22), rem(28)],
      letterSpacing: "-1px",
    },
    h5: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: 0.95,
      fontSize: [rem(16), null, null, rem(22)],
      letterSpacing: "-1px",
    },
    h6: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: 0.95,
      fontSize: [rem(16), null, null, rem(20)],
    },
    p: {
      color: "darkPurple",
      fontFamily: "body",
      lineHeight: 1.6,
      fontSize: [rem(16), null, null, rem(20)],
    },
  },
};

export default theme;

// buttons need to match link size
